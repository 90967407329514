/*!
 * CSS untuk custom 
 */

.bg-gradient-darkf {
    background-color: #0077ae;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #0077ae), to(#62d7ff));
    background-image: linear-gradient(180deg, #0077ae 10%, #62d7ff 100%);
    background-size: cover;
}

.sidebar .sidebar-brand{
    height: unset;
}

body {
    background-color: #efeff6;
}

.top-brand{
    margin-bottom: 15px;
}

.top-brand a:hover,.top-brand a:visited,.top-brand a:active{
    text-decoration: none;
}

.top-brand-img{
    width: 80px;
    height: 80px;
}
.top-brand-text{
    color : #3d405c;
    font-weight: 800;
    font-size: 1.5rem;
    text-decoration: none;
    margin-top: 5px;
    margin-bottom: 5px;
}

.btn-primary-f{
    background-color: #52567a;
    color: #ffffff;
}

.btn-primary-f:hover{
    background-color: #3d405c;
    color: #ffffff;
}



.alert-bottom-right{
    position: fixed;
    bottom: 10px;
    right: 20px;
    z-index: 10000000000;
    display: none;
}

.alert-bottom-center{
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 10000000000;
    display:none;
}

.alert-bottom-left{
    position: fixed;
    bottom: 10px;
    left: 20px;
    z-index: 10000000000;
    display:none;
}

.alert-top-right{
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 10000000000;
    display:none;
}

.alert-top-center{
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 10000000000;
    display:none;
}

.alert-top-left{
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 10000000000;
    display:none;
}

.alert-show {
    display: block;
}

.wysiwyg-editor{
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
}
.wysiwyg-editor:focus-within  {
    color: #6e707e;
    background-color: #fff;
    border-color: #bac8f3;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.wysiwyg-editor .DraftEditor-editorContainer{
    padding: 0px 10px;
    min-height: 200px;
}

.top-progressbar{
    position: fixed;
    top:0;
    left:0;
    right: 0;
    z-index: 10000000;
    display:none
}

.show-top-progressbar{
    display: block;
}

.top-progressbar .progress{
    height: 4px;
}

.top-progressbar .progress-bar{
    background-color: #ff7237;
}

.my-table .row{
    margin-right: 0;
    padding-left: 16px;
    padding-right: 16px;

}

.card-item-gall{
    position: relative;
    overflow: hidden;
}
.row-delete{
    position: absolute;
    top: -100%;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    transition: 0.9s ease-in-out;
}
.card-item-gall:hover .row-delete{
    top: 0;
}

.ul-menu-structure, .ul-menu-structure{
    list-style: none;
    margin: 0;
    padding:0
}
.ul-menu-structure li{
    list-style: none;
}

.ul-menu-structure li span {
    margin-bottom: 4px;
    background-color: #efefef;
    border-left: 4px solid #666;
    padding: 5px;
    display: block;
}

.nestable-item-name{
    border :1px solid #efefef;
    border-left: 4px solid #666;
    padding: 7px 5px;
    display: block;
}

.nestable-item, .nestable-item-copy{
    margin : 5px 0 0;
}

.nestable-item .nestable-list, .nestable-item-copy .nestable-list{
    margin-top: 5px;
}

.sia-container{
    padding: 10px 14px;
}

.w-140px{
    width: 140px;
}

.row-deleted{
    text-decoration: line-through;
    opacity: 0.5;
}

.table-form .form-group{
    margin-bottom:0 !important
}
.sia-dashboard-info {
    font-size: 0.9rem;
}

.custom-table{
    width: 100%;
}
.site-title{margin-bottom: 0;}

.fixed-modal {
    height: calc(100vh - 230px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.react-pdf__Page__canvas{
    box-shadow: 1px 1px 1px #000;
    border:1px solid #999;
    margin: 0px auto 0px auto;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    
    background-color: #f3f3f3;
  }
  
  /* Track */
  .custom-scrollbar::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey;  */
    border-radius: 4px;
  }
   
  /* Handle */
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: rgb(214, 214, 214); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #d8d8d8; 
  }

@media only screen and (max-width: 700px) {
    .site-title {
      font-size: 18px;
      /* font-weight: 600; */
      white-space: nowrap;
      overflow: hidden;
    }
  }
